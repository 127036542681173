'use strict';
import https from 'https';

export default function({ $axios, $config }) {
    if ($config.apiToken) {
        $axios.setToken($config.apiToken);
    }

    // Ignore https errors on local enviroments.
    if (process.env.APP_ENV === 'development') {
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        $axios.onRequest((config) => {
            config.httpsAgent = agent;
        });
    }
}
