'use strict';

export default {
    computed: {
        faviconLink() {
            return [
                { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicon.ico' },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
                { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
                { rel: 'mask-icon', href: '/safari-pinned-tab.svg' },
                { rel: 'manifest', href: '/site.webmanifest', crossorigin: 'use-credentials' },
            ];
        },
        faviconMeta() {
            return [
                { name: 'msapplication-TileColor', content: this.faviconThemeColor },
                { name: 'msapplication-config', content: '/browserconfig.xml' },
                { name: 'theme-color', content: this.faviconThemeColor }
            ];
        },
        faviconThemeColor() {
            return '#006BA7';
        }
    }
};
