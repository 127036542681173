export default {
    title: 'Krauthammer',
    menu: {
        'cta-button': 'Méthodologie Krauthammer'
    },
    'language-switcher': {
        'switch-to': 'Choisir la langue'
    },
    language: {
        english: 'Anglais',
        dutch: 'Néerlandais',
        french: 'Français',
        german: 'Allemand',
        czech: 'Tchèque'
    },
    country: {
        Netherlands: 'Pays-Bas',
        Belgium: 'Belgique',
        France: 'France',
        Germany: 'Allemagne'
    },
    footer: {
        'our-expertise': 'Notre expertise',
        contact: 'Nous contacter'
    },
    map: {
        offices: 'Nos bureaux',
        partners: 'Nos bureaux partenaires'
    },
    overviewPage: {
        'read-more': 'En savoir plus',
        'start-date': 'Prochaine formation: {date}',
        'empty-state': 'Aucun résultat trouvé'
    },
    filter: {
        categories: {
            topic: 'Thème',
            participant: 'Participant',
            city: 'Ville',
            language: 'Langue',
            consultantCountries: 'Pays de résidence',
            consultantIndustries: 'Secteurs d\'activité',
            consultantLanguages: 'Langues parlées',
            topics: 'Thèmes',
            industries: 'Secteurs d\'activité',
            country: 'Pays'
        },
        clear: 'Effacer les filtres'
    },
    detailPage: {
        register: 'S\'inscrire',
        locations: 'Lieux inspirants'
    },
    topicLandingPage: {
        back: 'Tous les programmes'
    },
    search: {
        placeholder: 'Rechercher',
        resultsFound: 'Aucun résultat trouvé| 1 résultat pour"{query}" | {count} résultats pour"{query}"',
        entryNames: {
            consultants_default_Entry: 'Consultant',
            programs_programs_Entry: 'Programme',
            blogs_default_Entry: 'Blog',
            cases_default_Entry: 'Référence clients',
            consultantsOverview_consultantsOverview_Entry: '',
            casesOverview_casesOverview_Entry: '',
            programsOverview_programsOverview_Entry: '',
            officesOverview_officesOverview_Entry: '',
            page_page_Entry: ''
        }
    },
    training: {
        title: 'Qui suivra cette formation?',
        subtitle: 'Les champs marqués d’un * sont obligatoires',
        labels: {
            location: 'Lieu',
            'training-days': 'Jours de formation',
            language: 'Langue',
            price: 'Prix'
        },
        details: 'Détails',
        firstDate: 'Prochaine formation',
        brochure: 'Brochure',
        register: 'S\'inscrire',
        'formatted-price': 'EUR {price} (HT)'
    },
    errorPage: {
        'page-not-found': {
            title: 'Page introuvable',
            description: 'Nous sommes désolés, la page que vous recherchez est introuvable.',
            'home-button-text': 'Retour à la page d\'accueil'
        },
        'something-went-wrong': {
            title: 'Une erreur s\'est produite',
            description: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
            'home-button-text': 'Retour à la page d\'accueil'
        }
    },
    registrationForm: {
        headers: {
            participants: 'Participants',
            enroll: 'Vous pouvez inscrire jusqu\'à 3 participants. Si vous souhaitez inscrire d\'autres participants, veuillez nous le faire savoir dans le champs “commentaires” à la fin de ce formulaire.',
            firstParticipant: 'Premier participant',
            secondParticipant: 'Deuxième participant',
            thirdParticipant: 'Troisième participant',
            invoiceDetails: 'Facturation',
            invoiceAddress: 'Adresse de facturation',
            contactPerson: 'Personne de contact pour cette inscription',
            accessibility: 'Accessibilité'
        },
        errors: {
            generic: 'Veuillez corriger les erreurs suivantes avant de soumettre ce formulaire'
        },
        fields: {
            firstName: {
                name: 'Prénom',
                label: 'Prénom',
                placeholder: ''
            },
            lastName: {
                name: 'Nom',
                label: 'Nom',
                placeholder: ''
            },
            func: {
                name: 'Fonction',
                label: 'Fonction',
                placeholder: ''
            },
            company: {
                name: 'Société',
                label: 'Société',
                placeholder: ''
            },
            email: {
                name: 'E-mail',
                label: 'E-mail',
                placeholder: ''
            },
            telephone: {
                name: 'Téléphone',
                label: 'Téléphone',
                placeholder: ''
            },
            address: {
                name: 'Adresse',
                label: 'Adresse',
                placeholder: ''
            },
            zipCode: {
                name: 'Code postal',
                label: 'Code postal',
                placeholder: ''
            },
            city: {
                name: 'Ville',
                label: 'Ville',
                placeholder: ''
            },
            country: {
                name: 'Pays',
                label: 'Pays',
                placeholder: ''
            },
            vat: {
                name: 'Numéro de TVA / TVA intracommunautaire',
                label: 'Numéro de TVA / TVA intracommunautaire',
                placeholder: ''
            },
            invoice: {
                name: 'Numéro de facture',
                label: 'Numéro de facture',
                placeholder: ''
            },
            comments: {
                name: 'Commentaires',
                label: 'Commentaires ou remarques',
                placeholder: ''
            },
            frenchFundingRequirements: {
                name: 'Besoins de financement français',
                label: 'Besoins de financement français',
                company: {
                    label: 'Le règlement de EUR {0} HT (+ TVA 20 %) sera effectué par votre entreprise. Votre inscription sera définitive à réception de l’acompte correspondant à 50 % des frais de participation. Le solde devra être réglé avant la première journée du training.'
                },
                agency: {
                    label: 'Le règlement de EUR {0} HT (+ TVA 20 %) sera effectué par un organisme payeur. Dans ce cas, nous communiquer l’accord de prise en charge par e-mail'
                }
            },
            accessibilityRequirements: {
                specificRequirements: {
                    name: 'Est-ce que parmi la liste ci-dessus, y a-t-il un ou des participants qui nécessite d’avoir un aménagement spécifique?',
                    label: 'Est-ce que parmi la liste ci-dessus, y a-t-il un ou des participants qui nécessite d’avoir un aménagement spécifique?',
                    yes: 'Oui',
                    no: 'Non'
                },
                comments: {
                    name: 'Commentaires',
                    label: 'Commentaires',
                    placeholder: ''
                }
            },
            terms: {
                toc: 'conditions générales',
                name: 'J\'accepte les Conditions Générales',
                description: 'Afin de vous fournir le contenu demandé, nous devons stocker et traiter vos données personnelles. Si vous consentez à ce que nous stockions vos données personnelles et acceptez nos Conditions générales à cette fin, veuillez cocher la case ci-dessous.',
                label: 'J\'accepte les J\'accepte les {0} et autorise Krauthammer à stocker et à traiter mes données personnelles.',
                privacy: 'politique de confidentialité',
                cookie: 'déclaration sur les cookies',
                info: 'Pour plus d\'informations, veuillez consulter notre {0} et notre {1}.',
                placeholder: ''
            },
            communications: {
                name: 'J\'accepte de recevoir des mises à jour marketing de Krauthammer.',
                description: 'De temps à autre, nous vous contacter au sujet de nos produits et services, ainsi que d\'autres contenus susceptibles de vous intéresser. Vous pouvez vous désabonner de ces communications à tout moment. Si vous consentez à ce que nous vous contactions à cette fin, veuillez cocher ci-dessous.',
                label: 'J\'accepte de recevoir d\'autres communications de Krauthammer.',
                info: 'Vous pouvez vous désabonner de ces communications à tout moment. Pour plus d\'informations sur la façon de vous désinscrire, nos pratiques de confidentialité et la façon dont nous nous engageons à protéger et à respecter votre vie privée, veuillez consulter notre {0}.',
                privacy: 'Politique de confidentialité',
                placeholder: ''
            },
            contactpersonParticipant: {
                name: '',
                label: 'Les coordonnées du Participant 1 sont les mêmes que celles de la personne de contact',
                placeholder: ''
            },
            submit: {
                label: 'Valider'
            }
        }
    },
    urls: {
        'terms-and-conditions': '/modalites-et-conditions',
        'cookie-statement': '/la-politique-des-cookies',
        'privacy-policy': '/declaration-de-confidentialite',
    }
};
