import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=3bd16e63"
import script from "./TheHeader.vue?vue&type=script&lang=js"
export * from "./TheHeader.vue?vue&type=script&lang=js"
import style0 from "./TheHeader.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNavigation: require('/vercel/path0/components/BaseNavigation/BaseNavigation.vue').default,BaseIcon: require('/vercel/path0/components/BaseIcon/BaseIcon.vue').default,NavLink: require('/vercel/path0/components/NavLink/NavLink.vue').default,SocialMedia: require('/vercel/path0/components/SocialMedia/SocialMedia.vue').default,TheHeaderMenu: require('/vercel/path0/components/TheHeaderMenu/TheHeaderMenu.vue').default})
