
export default {
    props: {
        width: {
            type: String,
            default: 'page',
            validator(value) {
                return ['page', 'content', 'bleed'].includes(value);
            }
        },
        background: {
            type: String,
            default: 'none',
            validator(value) {
                return ['none', 'default', 'lightest', 'primary', 'support-01', 'support-02'].includes(value);
            }
        }
    }
};
