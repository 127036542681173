
export default {
    props: {
        notFound: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    computed: {
        title() {
            if (this.notFound) {
                return this.$t('errorPage.page-not-found.title');
            } else {
                return this.$t('errorPage.something-went-wrong.title');
            }
        },
        message() {
            if (this.notFound) {
                return this.$t('errorPage.page-not-found.description');
            } else {
                return this.$t('errorPage.something-went-wrong.description');
            }
        },
        buttonText() {
            return this.$t('errorPage.page-not-found.home-button-text');
        }
    }
};
