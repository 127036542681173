import { render, staticRenderFns } from "./BaseNavigation.vue?vue&type=template&id=65296224"
import script from "./BaseNavigation.vue?vue&type=script&lang=js"
export * from "./BaseNavigation.vue?vue&type=script&lang=js"
import style0 from "./BaseNavigation.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkBlock: require('/vercel/path0/components/LinkBlock/LinkBlock.vue').default})
