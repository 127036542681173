export default {
    title: 'Krauthammer',
    menu: {
        'cta-button': 'De Krauthammer Methode'
    },
    'language-switcher': {
        'switch-to': 'Overschakelen naar'
    },
    language: {
        english: 'Engels',
        dutch: 'Nederlands',
        french: 'Frans',
        german: 'Duits',
        czech: 'Tsjechisch'
    },
    country: {
        Netherlands: 'Nederland',
        Belgium: 'België',
        France: 'Frankrijk',
        Germany: 'Duitsland'
    },
    footer: {
        'our-expertise': 'Onze expertise',
        contact: 'Kom in contact'
    },
    map: {
        offices: 'Krauthammer kantoren',
        partners: 'Partner kantoren'
    },
    overviewPage: {
        'read-more': 'Lees meer',
        'start-date': 'Eerste training: {date}',
        'empty-state': 'Geen resultaten gevonden'
    },
    filter: {
        categories: {
            topic: 'Onderwerpen',
            participant: 'Voor wie',
            city: 'Stad',
            language: 'Taal',
            consultantCountries: 'Landen',
            consultantIndustries: 'Industrieën',
            consultantLanguages: 'Gesproken talen',
            topics: 'Onderwerpen',
            industries: 'Industrieën',
            country: 'Land'
        },
        clear: 'Filters verwijderen'
    },
    detailPage: {
        register: 'Schrijf je in',
        locations: 'Inspirerende locaties'
    },
    topicLandingPage: {
        back: 'Alle programma\'s'
    },
    search: {
        placeholder: 'Zoeken',
        resultsFound: 'Geen resultaten gevonden| 1 resultaat voor"{query}" | {count} resultaten voor"{query}"',
        entryNames: {
            consultants_default_Entry: 'Consultant',
            programs_programs_Entry: 'Programma',
            blogs_default_Entry: 'Verhaal',
            cases_default_Entry: 'Klantcases',
            consultantsOverview_consultantsOverview_Entry: '',
            casesOverview_casesOverview_Entry: '',
            programsOverview_programsOverview_Entry: '',
            officesOverview_officesOverview_Entry: '',
            page_page_Entry: ''
        }
    },
    training: {
        title: 'Wie volgt deze training?',
        subtitle: 'Velden die zijn gemarkeerd met een * zijn verplichte velden',
        labels: {
            location: 'Locatie',
            'training-days': 'Trainingsdagen',
            language: 'Taal',
            price: 'Prijs'
        },
        details: 'Details',
        firstDate: 'Eerste training',
        brochure: 'Brochure',
        register: 'Schrijf je in',
        'formatted-price': 'EUR {price} (excl. btw)'
    },
    errorPage: {
        'page-not-found': {
            title: 'Pagina niet gevonden',
            description: 'Sorry! We kunnen de pagina die u zoekt niet vinden, misschien is het verplaatst of verwijderd.',
            'home-button-text': 'Ga terug naar de home pagina'
        },
        'something-went-wrong': {
            title: 'Er is iets misgegaan',
            description: 'Er is iets misgegaan bij het ophalen van de pagina die u probeerde te bezoeken. Probeer het later nog eens.',
            'home-button-text': 'Ga terug naar de home pagina'
        }
    },
    registrationForm: {
        headers: {
            participants: 'Deelnemers',
            enroll: 'U kunt maximaal 3 deelnemers tegelijk inschrijven. Om meer personen te registreren, gelieve de commentaarvelden aan het einde van dit formulier te gebruiken.',
            firstParticipant: 'Eerste deelnemer',
            secondParticipant: 'Tweede deelnemer',
            thirdParticipant: 'Derde deelnemer',
            invoiceDetails: 'Factuur gegevens',
            invoiceAddress: 'Factuur adres',
            contactPerson: 'Contactpersoon voor deze reservering',
            accessibility: 'Toegankelijkheid'
        },
        errors: {
            generic: 'Corrigeer de volgende fouten voordat u dit formulier indient'
        },
        fields: {
            firstName: {
                name: 'Voornaam',
                label: 'Voornaam',
                placeholder: ''
            },
            lastName: {
                name: 'Achternaam',
                label: 'Achternaam',
                placeholder: ''
            },
            func: {
                name: 'Functie',
                label: 'Functie',
                placeholder: ''
            },
            company: {
                name: 'Bedrijf',
                label: 'Bedrijf',
                placeholder: ''
            },
            email: {
                name: 'E-mailadres',
                label: 'E-mailadres',
                placeholder: ''
            },
            telephone: {
                name: 'Telefoon',
                label: 'Telefoon',
                placeholder: ''
            },
            address: {
                name: 'Adres',
                label: 'Adres',
                placeholder: ''
            },
            zipCode: {
                name: 'Postcode',
                label: 'Postcode',
                placeholder: ''
            },
            city: {
                name: 'Stad',
                label: 'Stad',
                placeholder: ''
            },
            country: {
                name: 'Land',
                label: 'Land',
                placeholder: ''
            },
            vat: {
                name: 'Btw-nummer',
                label: 'Btw-nummer',
                placeholder: ''
            },
            invoice: {
                name: 'Factuur referentie',
                label: 'Factuur referentie',
                placeholder: ''
            },
            comments: {
                name: 'Opmerkingen',
                label: 'Opmerkingen',
                placeholder: ''
            },
            frenchFundingRequirements: {
                name: 'Franse financieringsbehoeften',
                label: 'Franse financieringsbehoeften',
                company: {
                    label: 'De betaling van EUR {0} HT (+ 20% btw) wordt door uw bedrijf gedaan. Uw inschrijving is definitief na ontvangst van de aanbetaling die overeenkomt met 50% van het deelnamebedrag. Het saldo dient voor de eerste trainingsdag betaald te zijn.'
                },
                agency: {
                    label: 'De betaling van EUR {0} excl. btw (+ 20% btw) geschiedt door een betaalorgaan. Stuur ons in dat geval de supportovereenkomst per e-mail.'
                }
            },
            accessibilityRequirements: {
                specificRequirements: {
                    name: 'Hebben één of meerdere deelnemers behoefte aan een specifiek arrangement?',
                    label: 'Hebben één of meerdere deelnemers behoefte aan een specifiek arrangement?',
                    yes: 'Ja',
                    no: 'Nee'
                },
                comments: {
                    name: 'Opmerkingen',
                    label: 'Opmerkingen',
                    placeholder: ''
                }
            },
            terms: {
                toc: 'Algemene voorwaarden',
                name: 'Ik accepteer de Algemene voorwaarden',
                description: 'Om u te voorzien van de gevraagde inhoud, moeten wij uw persoonlijke gegevens opslaan en verwerken. Indien u ermee instemt dat wij uw persoonlijke gegevens opslaan en u gaat akkoord met onze Algemene Voorwaarden voor dit doel, vink dan het onderstaande vakje aan.',
                label: 'Ik accepteer de {0} en geef Krauthammer toestemming om mijn persoonlijke gegevens op te slaan en te verwerken',
                privacy: 'Privacy Policy',
                cookie: 'Cookie Statement',
                info: 'Voor meer informatie, gelieve onze {0} & {1} te bekijken',
                placeholder: ''
            },
            communications: {
                name: 'Ik ga ermee akkoord andere berichten van Krauthammer te ontvangen.',
                description: 'Van tijd tot tijd willen wij contact met u opnemen over onze producten en diensten, alsmede over andere inhoud die voor u van belang kan zijn. U kunt zich op elk gewenst moment afmelden voor deze communicatie. Als u ermee instemt dat wij contact met u opnemen voor dit doel, vink dan hieronder aan.',
                label: 'Ik ga akkoord met het ontvangen van marketing updates van Krauthammer',
                info: 'U kunt zich op elk moment afmelden voor deze communicatie. Voor meer informatie over hoe u zich kunt afmelden, onze privacypraktijken en hoe we ons inzetten om uw privacy te beschermen en te respecteren, raadpleegt ons {0} voor meer informatie.',
                privacy: 'Privacybeleid',
                placeholder: ''
            },
            contactpersonParticipant: {
                name: '',
                label: 'Gegevens van deelnemer 1 zijn dezelfde als deze van de contactpersoon',
                placeholder: ''
            },
            submit: {
                label: 'Verzend'
            }
        }
    },
    urls: {
        'terms-and-conditions': '/algemene-voorwaarden',
        'cookie-statement': '/cookies',
        'privacy-policy': '/privacystatement',
    }
};
