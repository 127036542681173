'use strict';

import { HOME_URI } from '~/constants/page-uri';

export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'linkBlock_linkInternal_BlockType':
                return INTERNAL;
            case 'linkBlock_linkExternal_BlockType':
                return EXTERNAL;
            }
        },
        mapURI(link) {
            switch (this.mapType(link.__typename)) {
            case INTERNAL:
            {
                const uri = link?.linkInternal[0]?.uri ?? '',
                    url = this.fixHomeUri(uri);

                return this.localePath(`/${url}`);
            }
            case EXTERNAL:
                if (typeof link === 'string') {
                    if (link[0] === '/') {
                        // If route parameter is a path, create route object with path.
                        return link;
                    }
                }
                return link.linkExternal;
            }
        },
        mapTarget(isTargetBlank) {
            if (isTargetBlank) {
                return '_blank';
            }
            return '_self';
        },
        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return '';
            }

            return uri;
        }
    }
};
