
export default {
    props: {
        image: {
            type: Object,
            default() { return null; }
        },
        caption: {
            type: String,
            default: ''
        },
        sizes: {
            type: String,
            default: '(min-width: 768px) 1020px, 100vw'
        }
    },
    computed: {
        src() {
            return this.image?.url ?? null;
        },
        height() {
            return this.image?.height ?? null;
        },
        width() {
            return this.image?.width ?? null;
        },
        srcset() {
            return Object.entries(this.image).map(([key, value]) => {
                const matches = key.match(/w([\d]+)/);

                if (value && matches && matches[1]) {
                    return `${value} ${matches[1]}w`;
                }

                return false;
            })
                .filter(v => v)
                .join(',');
        },
        hasFocalPoint() {
            return this.image?.hasFocalPoint ?? false;
        },
        focalPoint() {
            return this.image?.focalPoint ?? null;
        }
    }
};
