
import { FocusLoop } from '@vue-a11y/focus-loop';

export default {
    components: {
        FocusLoop
    },

    data() {
        return {
            visible: false
        };
    },
    watch: {
        visible(boolean) {
            if (boolean) {
                document.body.classList.add('menu-open');
            } else if (document.body.classList.contains('menu-open')) {
                document.body.classList.remove('menu-open');
            }
        },
        $route() {
            this.visible = false;
        }
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && this.visible) {
                this.visible = false;
            }
        });
    },
    methods: {
        handleTrigger() {
            this.visible = !this.visible;
        }
    }
};
