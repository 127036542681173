export default {
    title: 'Krauthammer',
    menu: {
        'cta-button': 'The Krauthammer Method'
    },
    'language-switcher': {
        'switch-to': 'Switch to'
    },
    language: {
        english: 'English',
        dutch: 'Dutch',
        french: 'French',
        german: 'German',
        czech: 'Czech'
    },
    country: {
        Netherlands: 'The Netherlands',
        Belgium: 'Belgium',
        France: 'France',
        Germany: 'Germany'
    },
    footer: {
        'our-expertise': 'Our expertise',
        contact: 'Get in touch'
    },
    map: {
        offices: 'Krauthammer offices',
        partners: 'Partner offices'
    },
    overviewPage: {
        'read-more': 'Learn more',
        'start-date': 'First date: {date}',
        'empty-state': 'There are no results, try removing some filters'
    },
    filter: {
        categories: {
            topic: 'Program topics',
            participant: 'For who',
            city: 'City',
            language: 'Language',
            consultantCountries: 'Resident countries',
            consultantIndustries: 'Industries',
            consultantLanguages: 'Spoken languages',
            topics: 'Topics',
            industries: 'Industries',
            country: 'Country'
        },
        clear: 'Clear filters'
    },
    detailPage: {
        register: 'Register',
        locations: 'Inspiring locations'
    },
    topicLandingPage: {
        back: 'All Programs'
    },
    search: {
        placeholder: 'Search',
        resultsFound: 'No results found | 1 result for "{query}" | {count} results for "{query}"',
        entryNames: {
            consultants_default_Entry: 'Consultant',
            programs_programs_Entry: 'Program',
            blogs_default_Entry: 'Story',
            cases_default_Entry: 'Case',
            consultantsOverview_consultantsOverview_Entry: '',
            casesOverview_casesOverview_Entry: '',
            programsOverview_programsOverview_Entry: '',
            officesOverview_officesOverview_Entry: '',
            page_page_Entry: ''
        }
    },
    training: {
        title: 'Who will follow this training?',
        subtitle: 'Fields marked with an * are mandatory fields',
        labels: {
            location: 'Location',
            'training-days': 'Training days',
            language: 'Language',
            price: 'Price'
        },
        details: 'Details',
        firstDate: 'First date',
        brochure: 'Brochure',
        register: 'Register',
        'formatted-price': 'EUR {price} (excl. VAT)' // {price} is a numerical  value (example: 1725),
    },
    errorPage: {
        'page-not-found': {
            title: 'Page not found',
            description: 'Sorry! We can\'t seem to find the page you were looking for, it may have been moved or removed.',
            'home-button-text': 'Return to home'
        },
        'something-went-wrong': {
            title: 'Something went wrong',
            description: 'Something went wrong while retrieving the page you tried to visit. Please try again later.',
            'home-button-text': 'Return to home'
        }
    },
    registrationForm: {
        headers: {
            participants: 'Participants',
            enroll: 'You can enroll up to 3 participants in one go - if you want to enroll more people, please use the comments fields at the end of this form.',
            firstParticipant: 'First Participant',
            secondParticipant: 'Second Participant',
            thirdParticipant: 'Third Participant',
            invoiceDetails: 'Invoice details',
            invoiceAddress: 'Invoice address',
            contactPerson: 'Contact person for this booking',
            accessibility: 'Accessibility'
        },
        errors: {
            generic: 'Please correct the following errors before submitting this form'
        },
        fields: {
            firstName: {
                name: 'First Name',
                label: 'First Name',
                placeholder: ''
            },
            lastName: {
                name: 'Last Name',
                label: 'Last Name',
                placeholder: ''
            },
            func: {
                name: 'Function',
                label: 'Function',
                placeholder: ''
            },
            company: {
                name: 'Company',
                label: 'Company',
                placeholder: ''
            },
            email: {
                name: 'Email Address',
                label: 'Email Address',
                placeholder: ''
            },
            telephone: {
                name: 'Telephone',
                label: 'Telephone',
                placeholder: ''
            },
            address: {
                name: 'Address',
                label: 'Address',
                placeholder: ''
            },
            zipCode: {
                name: 'Zip Code',
                label: 'Zip Code',
                placeholder: ''
            },
            city: {
                name: 'City',
                label: 'City',
                placeholder: ''
            },
            country: {
                name: 'Country',
                label: 'Country',
                placeholder: ''
            },
            vat: {
                name: 'VAT Number',
                label: 'VAT Number',
                placeholder: ''
            },
            invoice: {
                name: 'Invoice Reference Number',
                label: 'Invoice Reference Number',
                placeholder: ''
            },
            comments: {
                name: 'Comments or Remarks',
                label: 'Comments or Remarks',
                placeholder: ''
            },
            frenchFundingRequirements: {
                name: 'French funding requirements',
                label: 'French funding requirements',
                company: {
                    label: 'The payment of EUR {0} excluding VAT (+ VAT 20%) will be made by your company. Your registration will be final upon receipt of the deposit corresponding to 50% of the participation fee. The balance must be paid before the first day of training.'
                },
                agency: {
                    label: 'Payment of EUR {0} excluding VAT (+ 20% VAT) will be made by a paying agency. In this case, send us the support agreement by e-mail.'
                }
            },
            accessibilityRequirements: {
                specificRequirements: {
                    name: 'Do one or more participants need a specific arrangement?',
                    label: 'Do one or more participants need a specific arrangement?',
                    yes: 'Yes',
                    no: 'No'
                },
                comments: {
                    name: 'Remarks',
                    label: 'Remarks',
                    placeholder: ''
                }
            },
            terms: {
                toc: 'Terms & Conditions',
                name: 'I accept the Terms & Conditions',
                description: 'In order to provide you with the content requested, we need to store and process your personal data. If you consent to us storing your personal data and agree with our Terms & Conditions for this purpose, please tick the checkbox below.',
                label: 'I accept the {0} and allow Krauthammer to store and process my personal data.',
                privacy: 'Privacy Policy',
                cookie: 'Cookie Statement',
                info: 'For more information, please review our {0} & {1}',
                placeholder: ''
            },
            communications: {
                name: 'I agree to receive other communications from Krauthammer.',
                description: 'From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You can unsubscribe from these communications at any time. If you consent to us contacting you for this purpose, please tick below.',
                label: 'I agree to receive marketing updates from Krauthammer',
                info: 'You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our {0}.',
                privacy: 'Privacy Policy',
                placeholder: ''
            },
            contactpersonParticipant: {
                name: '',
                label: 'Details of participant 1 are the same as the contact person',
                placeholder: ''
            },
            submit: {
                label: 'Submit'
            }
        }
    },
    urls: {
        'terms-and-conditions': '/terms-and-conditions',
        'cookie-statement': '/cookie-statement',
        'privacy-policy': '/privacy-policy',
    }
};
