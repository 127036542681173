export default (context, inject) => {
    const unprefixedPath = () => {
        return context.localePath(context.route.path, context.i18n.defaultLocale);
    };

    // Inject $hello(msg) in Vue, context and store.
    inject('unprefixedPath', unprefixedPath);

    // For Nuxt <= 2.12, also add 👇
    context.$unprefixedPath = unprefixedPath;
};
