
import { trim } from 'lodash';

export default {
    data() {
        return {
            active: false
        };
    },
    computed: {
        // workaround for language-switcher bug: https://gitlab.digitalnatives.nl/toolkit/toolkit/-/merge_requests/157
        baseUri() {
            return decodeURI(trim(this.$route.path.replace(this.regex, '$1'), '/'));
        },
        regex() {
            return new RegExp(`^/(?:${this.activeLanguage.code}/)?(.+)$`, 'i');
        },
        activeLanguage() {
            return this.$i18n.localeProperties;
        }
    },
    watch: {
        $route() {
            this.active = false;
        }
    }
};
