
import getFooter from '~/graphql/queries/getFooter.graphql';

export default {
    data() {
        return {
            footerPrimaryMenu: [],
            footerSecondaryMenu: [],
            footerTertiaryMenu: [],
            footerImage: null,
        };
    },
    async fetch() {
        const site = this.$i18n.localeProperties.craftSiteHandle,
            { data } = await this.$gql.executeQuery(getFooter, { site });

        if (data) {
            if (data.globalSets.length > 0) {
                // Map globalSets to data
                data.globalSets.forEach((set) => {
                    // Footer menus
                    if (this[set.handle] && set.entryLinks !== undefined) {
                        this[set.handle] = set.entryLinks;
                    }

                    // Footer image
                    if (set.handle === 'footerImage' && set.image.length > 0) {
                        this.footerImage = set.image[0];
                    }
                });
            }
        }
    },
    watch: {
        '$i18n.locale'() {
            this.$fetch();
        }
    }
};
