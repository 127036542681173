
export const TARGET_SELF = '_self';
export const TARGET_BLANK = '_blank';

export default {
    props: {
        external: {
            type: Boolean,
            default: false
        },
        url: {
            type: [String, Object],
            required: true
        },
        target: {
            type: String,
            default: TARGET_SELF
        },
        classes: {
            type: String,
            default: ''
        }
    },

    computed: {
        rel() {
            if (this.external ?? this.target === TARGET_BLANK) {
                return 'noopener noreferrer';
            }

            return '';
        }
    }
};
