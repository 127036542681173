import { render, staticRenderFns } from "./TheHeaderMenu.vue?vue&type=template&id=61ebcdb4"
import script from "./TheHeaderMenu.vue?vue&type=script&lang=js"
export * from "./TheHeaderMenu.vue?vue&type=script&lang=js"
import style0 from "./TheHeaderMenu.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitch: require('/vercel/path0/components/LanguageSwitch/LanguageSwitch.vue').default,BaseIcon: require('/vercel/path0/components/BaseIcon/BaseIcon.vue').default,BaseButton: require('/vercel/path0/components/BaseButton/BaseButton.vue').default})
