
import getMainMenu from '~/graphql/queries/getMainMenu.graphql';

import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
    data() {
        return {
            primaryMenu: [],
            callToAction: null,
            secondaryMenu: [],
            tertiaryMenu: []
        };
    },
    async fetch() {
        const site = this.$i18n.localeProperties.craftSiteHandle,
            { data } = await this.$gql.executeQuery(getMainMenu, { site });

        if (data && data.globalSets.length > 0) {
            // Map globalSets to data
            data.globalSets.forEach((set) => {
                if (this[set.handle]) {
                    this[set.handle] = set.entryLinks;
                }
            });

            this.callToAction = data?.globalSets?.[0]?.linkBlock?.[0] || null;
        }
    },
    watch: {
        '$i18n.locale'() {
            this.$fetch();
        }
    }
};
