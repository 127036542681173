
export default {
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
