export default {
    title: 'Krauthammer',
    menu: {
        'cta-button': 'Krauthammer metoda'
    },
    'language-switcher': {
        'switch-to': 'Přejít na'
    },
    language: {
        english: 'Angličtina',
        dutch: 'Holandština',
        french: 'Francouzština',
        german: 'Němčina',
        czech: 'Čestina'
    },
    country: {
        Netherlands: 'Holandsko',
        Belgium: 'Belgie',
        France: 'Francie',
        Germany: 'Německo'
    },
    footer: {
        'our-expertise': 'Naše specializace',
        contact: 'Kontakt'
    },
    map: {
        offices: 'Krauthamer pobočky',
        partners: 'Partnerské pobočky'
    },
    overviewPage: {
        'read-more': 'Více',
        'start-date': 'První rande: {date}',
        'empty-state': 'There are no results, try removing some filters'
    },
    filter: {
        categories: {
            topic: 'Téma programu',
            participant: 'Pro koho',
            city: 'Město',
            language: 'Jazyk',
            consultantCountries: 'Země',
            consultantIndustries: 'Specializace',
            consultantLanguages: 'Jazyk výuky',
            topics: 'Téma',
            industries: 'Odvětví',
            country: 'Země'
        },
        clear: 'Zrušit filtr'
    },
    detailPage: {
        register: 'Registrovat',
        locations: 'Místa výcviku'
    },
    topicLandingPage: {
        back: 'Všechny programy'
    },
    search: {
        placeholder: 'Hledat',
        resultsFound: 'Nenalezeno | 1 výsledek pro "{query}" | {count} výsledek pro "{query}"',
        entryNames: {
            consultants_default_Entry: 'Konzultant',
            programs_programs_Entry: 'Program',
            blogs_default_Entry: 'Příběh',
            cases_default_Entry: 'Case',
            consultantsOverview_consultantsOverview_Entry: '',
            casesOverview_casesOverview_Entry: '',
            programsOverview_programsOverview_Entry: '',
            officesOverview_officesOverview_Entry: '',
            page_page_Entry: ''
        }
    },
    training: {
        labels: {
            location: 'Místo',
            'training-days': 'Termín',
            language: 'Jazyk',
            price: 'Cena'
        },
        details: 'Detaily',
        firstDate: 'První den',
        brochure: 'Brožura',
        register: 'Registrovat',
        'formatted-price': 'EUR {price} (bez DPH)' // {price} is a numerical  value (example: 1725)
    },
    errorPage: {
        'page-not-found': {
            title: 'Stránka nenalezena',
            description: 'Bohužel nemůžeme najít stránku, kterou hledáte. Byla změněna nebo odstraněna.',
            'home-button-text': 'Domů'
        },
        'something-went-wrong': {
            title: 'Něco se pokazilo',
            description: 'Při načítání došlo k chybě. Prosím zkuste to znovu.',
            'home-button-text': 'Domů'
        }
    },
    registrationForm: {
        headers: {
            participants: 'Účastníci',
            enroll: 'Můžete zapsat až 3 účastníky najednou - pokud chcete zaregistrovat více lidí, použijte prosím v komentáři na konci tohoto formuláře.',
            firstParticipant: 'První účastník',
            secondParticipant: 'Druhý účastník',
            thirdParticipant: 'Třetí účastník',
            invoiceDetails: 'Fakturační údaje',
            invoiceAddress: 'Fakturační adresa',
            contactPerson: 'Kontaktní osoba',
            accessibility: 'Přístupnost'
        },
        errors: {
            generic: 'Před odesláním tohoto formuláře prosím opravte následující chyby'
        },
        fields: {
            firstName: {
                name: 'Jméno',
                label: 'Jméno',
                placeholder: ''
            },
            lastName: {
                name: 'Příjmení',
                label: 'Příjmení',
                placeholder: ''
            },
            func: {
                name: 'Function',
                label: 'Function',
                placeholder: ''
            },
            company: {
                name: 'Společnost',
                label: 'Společnost',
                placeholder: ''
            },
            email: {
                name: 'Email',
                label: 'Email',
                placeholder: ''
            },
            telephone: {
                name: 'Telefon',
                label: 'Telefon',
                placeholder: ''
            },
            address: {
                name: 'Adresa',
                label: 'Adresa',
                placeholder: ''
            },
            zipCode: {
                name: 'PSČ',
                label: 'PSČ',
                placeholder: ''
            },
            city: {
                name: 'Město',
                label: 'Město',
                placeholder: ''
            },
            country: {
                name: 'Země',
                label: 'Země',
                placeholder: ''
            },
            vat: {
                name: 'DPH',
                label: 'DPH',
                placeholder: ''
            },
            invoice: {
                name: 'Číslo faktury',
                label: 'Číslo faktury',
                placeholder: ''
            },
            comments: {
                name: 'Komentáře',
                label: 'Komentáře',
                placeholder: ''
            },
            accessibilityRequirements: {
                specificRequirements: {
                    name: 'Potřebuje jeden nebo více účastníků konkrétní ujednání?',
                    label: 'Potřebuje jeden nebo více účastníků konkrétní ujednání?',
                    yes: 'Ano',
                    no: 'Ne'
                },
                comments: {
                    name: 'Remarks',
                    label: 'Remarks',
                    placeholder: ''
                }
            },
            terms: {
                toc: 'Terms & Conditions',
                name: 'I accept the Terms & Conditions',
                description: 'In order to provide you with the content requested, we need to store and process your personal data. If you consent to us storing your personal data and agree with our Terms & Conditions for this purpose, please tick the checkbox below.',
                label: 'I accept the {0} and allow Krauthammer to store and process my personal data.',
                privacy: 'Privacy Policy',
                cookie: 'Cookie Statement',
                info: 'For more information, please review our {0} & {1}',
                placeholder: ''
            },
            communications: {
                name: 'I agree to receive other communications from Krauthammer.',
                description: 'From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You can unsubscribe from these communications at any time. If you consent to us contacting you for this purpose, please tick below.',
                label: 'I agree to receive marketing updates from Krauthammer',
                info: 'You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our {0}.',
                privacy: 'Privacy Policy',
                placeholder: ''
            },
            contactpersonParticipant: {
                name: '',
                label: 'Shodné s kontaktní osobou',
                placeholder: ''
            },
            submit: {
                label: 'Odeslat'
            }
        }
    }
};
