export default {
    title: 'Krauthammer',
    menu: {
        'cta-button': 'Die Krauthammer Methode'
    },
    'language-switcher': {
        'switch-to': 'Wechseln zu'
    },
    language: {
        english: 'Englisch',
        dutch: 'Holländisch',
        french: 'Französisch',
        german: 'Deutsch',
        czech: 'Tschechisch'
    },
    country: {
        Netherlands: 'Niederlande',
        Belgium: 'Belgien',
        France: 'Frankreich',
        Germany: 'Deutschland'
    },
    footer: {
        'our-expertise': 'Unsere Expertise',
        contact: 'Kontaktieren Sie uns'
    },
    map: {
        offices: 'Krauthammer Büros',
        partners: 'Partnerbüros'
    },
    overviewPage: {
        'read-more': 'Mehr erfahren',
        'start-date': 'Erster Trainingstermin: {date}',
        'empty-state': 'Keine Ergebnisse gefunden'
    },
    filter: {
        categories: {
            topic: 'Themen',
            participant: 'Zielgruppe',
            city: 'Stadt',
            language: 'Sprache',
            consultantCountries: 'Ansässige Länder',
            consultantIndustries: 'Industrien',
            consultantLanguages: 'Gesprochene Sprachen',
            topics: 'Themen',
            industries: 'Industrien',
            country: 'Land'
        },
        clear: 'Filter zurücksetzen'
    },
    detailPage: {
        register: 'Registrieren',
        locations: 'Inspirierende Orte'
    },
    topicLandingPage: {
        back: 'Alle Programme'
    },
    search: {
        placeholder: 'Suchen',
        resultsFound: 'Keine Ergebnisse gefunden | 1 Ergebnis für "{query}" | {count} Ergebnisse für "{query}"',
        entryNames: {
            consultants_default_Entry: 'Consultant',
            programs_programs_Entry: 'Programm',
            blogs_default_Entry: 'Blog',
            cases_default_Entry: 'Kundenfälle',
            consultantsOverview_consultantsOverview_Entry: '',
            casesOverview_casesOverview_Entry: '',
            programsOverview_programsOverview_Entry: '',
            officesOverview_officesOverview_Entry: '',
            page_page_Entry: ''
        }
    },
    training: {
        title: 'Wer nimmt an diesem Training teil?',
        subtitle: 'Mit einem * gekennzeichnete Felder sind Pflichtfelder',
        labels: {
            location: 'Ort',
            'training-days': 'Trainingstage',
            language: 'Sprache',
            price: 'Preis'
        },
        details: 'Details',
        firstDate: 'Erster Trainingstermin',
        brochure: 'Broschüre',
        register: 'Registrieren',
        'formatted-price': 'EUR {price} (zzgl. MwSt.)'
    },
    errorPage: {
        'page-not-found': {
            title: 'Seite nicht gefunden',
            description: 'Entschuldigung! Wir können die gesuchte Seite leider nicht finden, vielleicht wurde sie verschoben oder entfernt.',
            'home-button-text': 'Zur Startseite'
        },
        'something-went-wrong': {
            title: 'Etwas ist schief gegangen',
            description: 'Etwas ist schief gegangen beim Aufrufen dieser Seite. Bitte versuchen Sie es später noch einmal.',
            'home-button-text': 'Zur Startseite'
        }
    },
    registrationForm: {
        headers: {
            participants: 'Teilnehmer',
            enroll: 'Sie können bis zu 3 Teilnehmer gleichzeitig anmelden. Zur Anmeldung weiterer Personen verwenden Sie bitte die Kommentarfelder am Ende dieses Formulars.',
            firstParticipant: 'Erster Teilnehmer',
            secondParticipant: 'Zweiter Teilnehmer',
            thirdParticipant: 'Dritter Teilnehmer',
            invoiceDetails: 'Rechnungsangaben',
            invoiceAddress: 'Rechnungsadresse',
            contactPerson: 'Ansprechperson für diese Reservierung',
            accessibility: 'Barrierefreiheit'
        },
        errors: {
            generic: 'Bitte korrigieren Sie die folgenden Fehler, bevor Sie dieses Formular absenden'
        },
        fields: {
            firstName: {
                name: 'Vorname',
                label: 'Vorname',
                placeholder: ''
            },
            lastName: {
                name: 'Nachname',
                label: 'Nachname',
                placeholder: ''
            },
            func: {
                name: 'Funktion',
                label: 'Funktion',
                placeholder: ''
            },
            company: {
                name: 'Unternehmen',
                label: 'Unternehmen',
                placeholder: ''
            },
            email: {
                name: 'E-Mail-Adresse',
                label: 'E-Mail-Adresse',
                placeholder: ''
            },
            telephone: {
                name: 'Telefonnummer',
                label: 'Telefonnummer',
                placeholder: ''
            },
            address: {
                name: 'Adresse',
                label: 'Adresse',
                placeholder: ''
            },
            zipCode: {
                name: 'Postleitzahl',
                label: 'Postleitzahl',
                placeholder: ''
            },
            city: {
                name: 'Ort',
                label: 'Ort',
                placeholder: ''
            },
            country: {
                name: 'Land',
                label: 'Land',
                placeholder: ''
            },
            vat: {
                name: 'USt-IdNr.',
                label: 'USt-IdNr.',
                placeholder: ''
            },
            invoice: {
                name: 'Die Rechnungsreferenznummer',
                label: 'Die Rechnungsreferenznummer',
                placeholder: ''
            },
            comments: {
                name: 'Kommentare oder Anmerkungen',
                label: 'Kommentare oder Anmerkungen',
                placeholder: ''
            },
            frenchFundingRequirements: {
                name: 'Französische Finanzierungsanforderungen',
                label: 'Französische Finanzierungsanforderungen',
                company: {
                    label: 'Die Zahlung von EUR {0} HT (+ MwSt. 20%) erfolgt durch Ihr Unternehmen. Mit Eingang der Anzahlung in Höhe von 50 % der Teilnahmegebühr ist Ihre Anmeldung definitiv. Der Restbetrag muss vor dem ersten Schulungstag bezahlt werden.'
                },
                agency: {
                    label: 'Die Zahlung von {0} EUR ohne MwSt. (+ 20 % MwSt.) erfolgt durch eine Zahlstelle. Senden Sie uns in diesem Fall die Supportvereinbarung per E-Mail zu.'
                }
            },
            accessibilityRequirements: {
                specificRequirements: {
                    name: 'Benötigen ein oder mehrere Teilnehmer ein bestimmtes Arrangement?',
                    label: 'Benötigen ein oder mehrere Teilnehmer ein bestimmtes Arrangement?',
                    yes: 'Ja',
                    no: 'Nein'
                },
                comments: {
                    name: 'Bemerkungen',
                    label: 'Bemerkungen',
                    placeholder: ''
                }
            },
            terms: {
                toc: 'Allgemeinen Geschäftsbedingungen',
                name: 'Ich akzeptiere die Allgemeine Geschäftsbedingungen',
                description: 'Um Ihnen die gewünschten Inhalte zukommen lassen zu können, müssen wir Ihre persönlichen Daten speichern und verarbeiten. Wenn Sie damit einverstanden sind, dass wir Ihre persönlichen Daten zu diesem Zweck speichern und Sie unseren Allgemeinen Geschäftsbedingungen zustimmen, markieren Sie bitte das unten stehende Kästchen.',
                label: 'Ich akzeptiere die {0} und erlaube Krauthammer, meine persönlichen Daten zu speichern und zu verarbeiten.',
                privacy: 'Datenschutzrichtlienie',
                cookie: 'Cookie-Erklärung',
                info: 'Für weitere Informationen lesen Sie bitte unsere {0} und {1}',
                placeholder: ''
            },
            communications: {
                name: 'Ich stimme zu, andere Mitteilungen von Krauthammer zu erhalten.',
                description: 'Von Zeit zu Zeit würden wir Sie gerne über unsere Produkte und Dienstleistungen sowie über andere Inhalte, die für Sie von Interesse sein könnten, informieren. Sie können sich jederzeit von diesen Mitteilungen abmelden. Wenn Sie damit einverstanden sind, dass wir Sie zu diesem Zwecke kontaktieren, markieren Sie bitte das unten stehende Kästchen.',
                label: 'Ich bin damit einverstanden, Marketing-Updates von Krauthammer zu erhalten.',
                info: 'Sie können diese Mitteilungen jederzeit abbestellen. Weitere Informationen darüber, wie Sie sich abmelden können, unsere Datenschutzpraktiken und wie wir uns verpflichten, Ihre Privatsphäre zu schützen und zu respektieren, finden Sie in unserer {0}.',
                privacy: 'Datenschutz-Bestimmungen',
                placeholder: ''
            },
            contactpersonParticipant: {
                name: '',
                label: 'Die Angaben zu Teilnehmer 1 sind identisch mit denen der Ansprechperson',
                placeholder: ''
            },
            submit: {
                label: 'Senden'
            }
        }
    },
    urls: {
        'terms-and-conditions': '/bedingungen-und-konditionen',
        'cookie-statement': '/cookies',
        'privacy-policy': '/datenschutz',
    }
};
