import Vue from 'vue';

function inViewUpdate(entries) {
    for (const entry of entries) {
        entry.target.classList.toggle('inview--viewing', entry.isIntersecting);

        if (entry.isIntersecting) {
            entry.target.dispatchEvent(new Event('enter'));
            entry.target.classList.add('inview--viewed');
        } else {
            entry.target.dispatchEvent(new Event('leave'));
        }
    }
}

export default function() {
    const inViewOptions = {
        threshold: [0.25]
    };

    const inViewObserver = new IntersectionObserver(inViewUpdate, inViewOptions);

    Vue.directive('inview', {
        bind(el) {
            el.classList.add('inview--observing');
            inViewObserver.observe(el);
        },

        unbind(el) {
            if (el instanceof Element) {
                el.classList?.remove('inview--observing');
                inViewObserver.unobserve(el);
            }
        }
    });
}
