
export default {
    props: {
        items: {
            type: Array,
            required: false,
            default: null
        },
        size: {
            type: String,
            default: 'medium'
        },
        inline: {
            type: Boolean,
            default: false
        },
        underline: {
            type: Boolean,
            default: false
        }
    }
};
