
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
    props: {
        links: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    computed: {
        link() {
            return this.links?.[0] ?? null;
        }
    }
};
