import { render, staticRenderFns } from "./LinkBlock.vue?vue&type=template&id=7b7f3448"
import script from "./LinkBlock.vue?vue&type=script&lang=js"
export * from "./LinkBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavLink: require('/vercel/path0/components/NavLink/NavLink.vue').default})
